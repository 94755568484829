import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { withStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Container from "@material-ui/core/Container";

import SheetClient from "./SheetClient.js";
import AccountStatus from "./AccountStatus.js";
import MonthlyExpenses from "./MonthlyExpenses.js";
import LatestTransactions from "./LatestTransactions.js";
import Home from "./Home.js";
import DrawerContents from "./Drawer.js";

import { API_KEY, CLIENT_ID, SPREADSHEET_ID } from "./settings.js";

const DISCOVERY_DOCS = [
  "https://sheets.googleapis.com/$discovery/rest?version=v4"
];
const GAPPS_SCOPE = "https://www.googleapis.com/auth/spreadsheets";
const drawerWidth = 240;

const styles = theme => ({
  root: {
    // width: "100%",
    // height: "100%",
    // marginTop: 0,
    // zIndex: 1,
    // overflow: "scroll"
    display: "flex",
    flexGrow: 1
  },
  appFrame: {
    position: "static",
    display: "flex",
    width: "100%",
    height: "100%"
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    backgroundColor: "#199f28"
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  drawerHeader: theme.mixins.toolbar,
  drawerPaper: {
    width: 250,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "relative",
      height: "100%"
    }
  },
  toolbar: theme.mixins.toolbar,
  content: {
    backgroundColor: theme.palette.background.default,
    // width: "100%",
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      height: "calc(100% - 64px)",
      marginTop: 64
    }
  }
});

class App extends Component {
  state = {
    GoogleAuth: null,
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  initClient() {
    window.gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        scope: GAPPS_SCOPE,
        ux_mode: "redirect",
        discoveryDocs: DISCOVERY_DOCS
      })
      .then(x => {
        const ga = window.gapi.auth2.getAuthInstance();
        this.setState({
          GoogleAuth: ga,
          GoogleApi: window.gapi,
          SheetClient: new SheetClient(window.gapi, SPREADSHEET_ID)
        });
      });
  }

  componentDidMount() {
    window.gapi.load("client:auth2", this.initClient.bind(this));
  }

  render() {
    if (!this.state.GoogleAuth) return null;
    const { classes, theme } = this.props;

    return (
      <Router>
        <div className={classes.appFrame}>
          <AppBar className={classes.appBar} position="fixed">
            <Toolbar>
              <IconButton aria-label="Menu" onClick={this.handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <Typography
                color="inherit"
                style={{ flex: 1, textAlign: "left" }}
              >
                2025
              </Typography>
            </Toolbar>
          </AppBar>

          <Hidden mdUp>
            <Drawer
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              <DrawerContents onItemClick={this.handleDrawerToggle} />
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <DrawerContents />
            </Drawer>
          </Hidden>
          <Container>
            <div className={classes.toolbar} />

            <Route
              exact
              path="/"
              render={routeProps => (
                <Home
                  GoogleAuth={this.state.GoogleAuth}
                  client={this.state.SheetClient}
                />
              )}
            />
            <Route
              path="/accounts"
              render={routeProps => (
                <AccountStatus client={this.state.SheetClient} />
              )}
            />
            <Route
              path="/monthly-expenses"
              render={routeProps => (
                <MonthlyExpenses client={this.state.SheetClient} />
              )}
            />
            <Route
              path="/latest-transactions"
              render={routeProps => (
                <LatestTransactions client={this.state.SheetClient} />
              )}
            />
          </Container>
        </div>
      </Router>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
