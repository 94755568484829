import React from "react";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListIcon from "@material-ui/icons/List";

export default props => (
  <div>
    <div />
    <Divider />
    <List>
      <ListItem button component={Link} to={"/"} onClick={props.onItemClick}>
        <ListItemIcon>
          <AddShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Nuevo Movimiento" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to={"/accounts"}
        onClick={props.onItemClick}
      >
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary="Estado de Cuentas" />
      </ListItem>
      <ListItem
        button
        component={Link}
        to={"/latest-transactions"}
        onClick={props.onItemClick}
      >
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Últimos Movimientos" />
      </ListItem>
{/*        <ListItem
        button
        component={Link}
        to={"/monthly-expenses"}
        onClick={props.onItemClick}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Gastos Mensuales" />
      </ListItem>
 */}    </List>
    <Divider />
    <List />
  </div>
);
